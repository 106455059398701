<template>
  <div class="event">
    <div class="inner-text">
      <h1 class="text-white display-1 font-weight-bold event-head">Talent Hunt</h1>
      <div class="text-white submig-box">
        Shout-out to the videographers around the globe.
      </div>
      <div class="text-white submig-box mb-4">
        Submit your portfolio to the biggest talent hunt by
        <span>20<sup>th</sup> July 2021</span>.
      </div>
    </div>
    <a v-scroll-to="{el: '#registration',offset: -90}"
    class="btn-contact-lg" href="#registration">Register Now</a>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="stylus" scoped>
.event
  position absolute
  top 90px
  width 100%
  padding 100px 10%
.event-head
  font-size 90px
  font-weight bold
  text-transform uppercase
.btn-contact-lg
  font-size 150%
  color #fff !important
  background-color #ff0000 !important
  border-color #ff0000 !important
  padding 20px 30px 20px 30px
  border-radius 30px
  text-transform uppercase
  display inline-block
  margin-top 20px
.submig-box
  font-size 30px
@media screen and (max-width: 766px)
  .event
    top 100px
    width 100%
    padding 0 5%
  .event-head
    margin-top 20px
    font-size 50px
    font-weight bold
    margin-bottom 0
  .btn-contact-lg
    margin-top  0
    font-size 20px
    padding 5px 10px
    border-radius 20px
  .submig-box
    font-size 20px
@media screen and (max-width: 575px)
  .event
    top 50px
    width 100%
    padding 0 5%
  .event-head
    margin-top 20px
    font-size 25px
    font-weight bold
    margin-bottom 0
  .btn-contact-lg
    margin-top  0
    font-size 20px
    padding 5px 10px
    border-radius 20px
  .submig-box
    font-size 15px

</style>
